import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SignupService } from '../../services/signup.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router';
import { WatcherService } from 'src/app/services/watcher.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import * as AuthActions from '../../store/shared/auth/auth.actions';
import { register } from 'swiper/element/bundle';
import { shippnpilotReviews } from 'src/app/shared/contents/shippn-reviews/shippn-reviews-review';
declare var $;
@Component({
  selector: 'app-homepage',
  templateUrl: './homepage2.component.html',
  styleUrls: ['./homepage2.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class Homepage2Component implements OnInit, AfterViewInit {
  isThereCommercialBanner = true;
  topRatedRouteList = [
    {
      routeFrom: 'US',
      routeFromName: 'United States',
      routeFromFlag: 'https://www.shippn.com/assets/img/flags/united-states-of-america.png',
      routeTo: 'UK',
      routeToName: 'United Kingdom',
      routeToFlag: 'https://www.shippn.com/assets/img/flags/united-kingdom.png',
      routePrice: '$8.53'
    },
    {
      routeFrom: 'AU',
      routeTo: 'US',
      routePrice: '$14.21',
      routeFromName: 'Australia',
      routeFromFlag: 'https://www.shippn.com/assets/img/flags/australia.png',
      routeToName: 'United States',
      routeToFlag: 'https://www.shippn.com/assets/img/flags/united-states-of-america.png',
    },
    {
      routeFrom: 'AU',
      routeTo: 'UK',
      routePrice: '$17.70',
      routeFromName: 'Australia',
      routeFromFlag: 'https://www.shippn.com/assets/img/flags/australia.png',
      routeToName: 'United Kingdom',
      routeToFlag: 'https://www.shippn.com/assets/img/flags/united-kingdom.png',
    },
    {
      routeFrom: 'UK',
      routeTo: 'US',
      routePrice: '$12.83',
      routeFromName: 'United Kingdom',
      routeFromFlag: 'https://www.shippn.com/assets/img/flags/united-kingdom.png',
      routeToName: 'United States',
      routeToFlag: 'https://www.shippn.com/assets/img/flags/united-states-of-america.png',
    },
    {
      routeFrom: 'DE',
      routeTo: 'US',
      routePrice: '$6.08',
      routeFromName: 'Germany',
      routeFromFlag: 'https://www.shippn.com/assets/img/flags/germany.png',
      routeToName: 'United States',
      routeToFlag: 'https://www.shippn.com/assets/img/flags/united-states-of-america.png',
    },
    {
      routeFrom: 'DE',
      routeTo: 'UK',
      routePrice: '$6.11',
      routeFromName: 'Germany',
      routeFromFlag: 'https://www.shippn.com/assets/img/flags/germany.png',
      routeToName: 'United Kingdom',
      routeToFlag: 'https://www.shippn.com/assets/img/flags/united-kingdom.png',
    }
    , {
      routeFrom: 'JP',
      routeTo: 'UK',
      routePrice: '$11.30',
      routeFromName: 'Japan',
      routeFromFlag: 'https://www.shippn.com/assets/img/flags/japan.png',
      routeToName: 'United Kingdom',
      routeToFlag: 'https://www.shippn.com/assets/img/flags/united-kingdom.png',
    }
    , {
      routeFrom: 'US',
      routeTo: 'AU',
      routePrice: '$12.14',
      routeFromName: 'United States',
      routeFromFlag: 'https://www.shippn.com/assets/img/flags/united-states-of-america.png',
      routeToName: 'Australia',
      routeToFlag: 'https://www.shippn.com/assets/img/flags/australia.png',
    }
    , {
      routeFrom: 'UK',
      routeTo: 'DE',
      routePrice: '$6.08',
      routeFromName: 'United Kingdom',
      routeFromFlag: 'https://www.shippn.com/assets/img/flags/united-kingdom.png',
      routeToName: 'Germany',
      routeToFlag: 'https://www.shippn.com/assets/img/flags/germany.png',
    }
    , {
      routeFrom: 'US',
      routeTo: 'CA',
      routePrice: '$7.69',
      routeFromName: 'United States',
      routeFromFlag: 'https://www.shippn.com/assets/img/flags/united-states-of-america.png',
      routeToName: 'Canada',
      routeToFlag: 'https://www.shippn.com/assets/img/flags/canada.png',
    },
    {
      routeFrom: 'NL',
      routeTo: 'DE',
      routePrice: '$3.50',
      routeFromName: 'Netherlands',
      routeFromFlag: 'https://www.shippn.com/assets/img/flags/netherlands.png',
      routeToName: 'Germany',
      routeToFlag: 'https://www.shippn.com/assets/img/flags/germany.png',
    },
    {
      routeFrom: 'NL',
      routeTo: 'UK',
      routePrice: '$6.99',
      routeFromName: 'Netherlands',
      routeFromFlag: 'https://www.shippn.com/assets/img/flags/netherlands.png',
      routeToName: 'United Kingdom',
      routeToFlag: 'https://www.shippn.com/assets/img/flags/united-kingdom.png',
    }

  ]
  bf2022Brands = [
    {
      name: "North Face",
      logo: "../../../assets/img/landing-page-brands/northface.webp",
      link: "https://www.thenorthface.com/",
      discount: "25%"
    },
    {
      name: "Net-A-Porter",
      logo: "../../../assets/img/landing-page-brands/net-a-porter.webp",
      link: "https://www.net-a-porter.com/en-us/",
      discount: "50%"
    },
    {
      name: "Neiman Marcus",
      logo: "../../../assets/img/landing-page-brands/neiman-marcus.webp",
      link: "https://www.neimanmarcus.com/",
      discount: "75%"
    },
    {
      name: "Amazon",
      logo: "../../../assets/img/landing-page-brands/amazon.webp",
      link: "https://www.amazon.com/deal/37fa6533?searchAlias=fashion&moreDeals=2708fe12%2C55842a14&ref=dlx_black_gd_dcl_img_21_37fa6533_dt_sl7_af",
      discount: "54%"
    },
    {
      name: "Farfetch",
      logo: "../../../assets/img/landing-page-brands/farfetch.webp",
      link: "https://www.farfetch.com",
      discount: "50%"
    },
    {
      name: "Ralph Lauren",
      logo: "../../../assets/img/landing-page-brands/ralp_lauren.webp",
      link: "https://www.ralphlauren.com/",
      discount: "40%"
    },
    {
      name: "Alo Yoga",
      logo: "../../../assets/img/landing-page-brands/aloyoga.webp",
      link: "https://www.aloyoga.com/",
      discount: "70%"
    },
    {
      name: "Saks Fifth Aveneu",
      logo: "../../../assets/img/landing-page-brands/saks-fifth-avenue.webp",
      link: "https://www.saksfifthavenue.com/",
      discount: "75%"
    },
    {
      name: "Amazon",
      logo: "../../../assets/img/landing-page-brands/amazon.webp",
      link: "https://www.amazon.com/deal/37fa6533?searchAlias=fashion&moreDeals=2708fe12%2C55842a14&ref=dlx_black_gd_dcl_img_21_37fa6533_dt_sl7_af",
      discount: "54%"
    },
    {
      name: "GAP",
      logo: "../../../assets/img/landing-page-brands/gap.webp",
      link: "https://www.gap.com/",
      discount: "50%"
    },
    {
      name: "Macy's",
      logo: "../../../assets/img/landing-page-brands/macys.webp",
      link: "https://www.macys.com/",
      discount: "65%"
    },
    {
      name: "Nike",
      logo: "../../../assets/img/landing-page-brands/nike.webp",
      link: "https://www.nike.com/",
      discount: "20%"
    },
    {
      name: "Amazon",
      logo: "../../../assets/img/landing-page-brands/amazon.webp",
      link: "https://www.amazon.com/",
      discount: "54%"
    },
    {
      name: "New Balance",
      logo: "../../../assets/img/landing-page-brands/newbalance.webp",
      link: "https://www.newbalance.com/",
      discount: "25%"
    },
    {
      name: "Asos",
      logo: "../../../assets/img/landing-page-brands/asos.webp",
      link: "https://www.asos.com/",
      discount: "80%"
    },
    {
      name: "Amazon",
      logo: "../../../assets/img/landing-page-brands/amazon.webp",
      link: "https://www.amazon.com/deal/37fa6533?searchAlias=fashion&moreDeals=2708fe12%2C55842a14&ref=dlx_black_gd_dcl_img_21_37fa6533_dt_sl7_af",
      discount: "54%"
    },


  ]
  cm2022Brands = [

    {
      name: "Best Buy",
      logo: "../../../assets/img/landing-page-brands/bestbuy.webp",
      link: "https://www.bestbuy.com",
      discount: "50%"
    },
    {
      name: "Adidas",
      logo: "../../../assets/img/landing-page-brands/adidas.webp",
      link: "https://www.adidas.com/us",
      discount: "40%"
    },
    {
      name: "Apple",
      logo: "../../../assets/img/landing-page-brands/apple.webp",
      link: "https://www.apple.com/",
      discount: "75$"
    },
    {
      name: "Amazon",
      logo: "../../../assets/img/landing-page-brands/amazon.webp",
      link: "https://www.amazon.com",
      discount: "50%"
    },

    {
      name: "Walmart",
      logo: "../../../assets/img/landing-page-brands/walmart.webp",
      link: "https://www.walmart.com/",
      discount: "40%"
    },
    {
      name: "Dell",
      logo: "../../../assets/img/landing-page-brands/dell.webp",
      link: "https://www.dell.com/en-us",
      discount: "60%"
    },
    {
      name: "Home Depot",
      logo: "../../../assets/img/landing-page-brands/homedepot.webp",
      link: "https://www.homedepot.com/",
      discount: "50%"
    },
    {
      name: "Nectar",
      logo: "../../../assets/img/landing-page-brands/nectar.webp",
      link: "https://www.nectarsleep.com/",
      discount: "33%"
    },
    {
      name: "Nordstorm",
      logo: "../../../assets/img/landing-page-brands/nordstorm.webp",
      link: "https://www.nordstrom.com/",
      discount: "50%"
    },
    {
      name: "Target",
      logo: "../../../assets/img/landing-page-brands/target.webp",
      link: "https://www.target.com/",
      discount: "50%"
    },

    {
      name: "Overstock",
      logo: "../../../assets/img/landing-page-brands/overstock.webp",
      link: "https://www.overstock.com/",
      discount: "70%"
    },
    {
      name: "Wayfair",
      logo: "../../../assets/img/landing-page-brands/wayfair.webp",
      link: "https://www.wayfair.com/",
      discount: "80%"
    },
    {
      name: "Macy's",
      logo: "../../../assets/img/landing-page-brands/macys.webp",
      link: "https://www.macys.com/",
      discount: "50%"
    },
    {
      name: "Samsung",
      logo: "../../../assets/img/landing-page-brands/samsung.webp",
      link: "https://www.samsung.com/us/",
      discount: "40%"
    },
    {
      name: "Farfetch",
      logo: "../../../assets/img/landing-page-brands/farfetch.webp",
      link: "https://www.farfetch.com",
      discount: "20%"
    },
    {
      name: "Neiman Marcus",
      logo: "../../../assets/img/landing-page-brands/neiman-marcus.webp",
      link: "https://www.neimanmarcus.com/",
      discount: "50%"
    },



  ]
  userID;
  token;
  anonymousID;
  cartID;
  isDisabled = true;
  topRatedHostList;
  hostCountries;
  requestNonHostList;
  allCountries;
  countryFrom = 'au';
  countryTo;
  BFMEmail;
  wordpressData;
  instagramToken;
  BFMTab = false;
  shopFromTab = true;
  mobileBFMEmail;
  desktopBFMTab = true;
  desktopShopFromTab = true;
  profileDetails;
  bfmPurchaseList;
  form = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    country: new UntypedFormControl('AW'),
  })
  bfmMailForm = new UntypedFormGroup({
    bfmMail: new UntypedFormControl('', [Validators.required]),
  })
  lang = 'en';
  shoppingFavoriteProducts: any[];

  shippnpilotReviews = shippnpilotReviews;
  hostCountriesCount = Number(localStorage.getItem('hostCountriesCount')) ?? 40;


  constructor(
    private router: ActivatedRoute,
    public signUpService: SignupService,
    public watcherService: WatcherService,
    public toastr: ToastrService,
    private route: Router,
    private store: Store<AppState>,
    private cdr: ChangeDetectorRef,

  ) { }


  commercialBannerImage = 'https://d1we2hgzmci25y.cloudfront.net/assets/img/valentines_day_2024_banner_desktop.webp';


  shopInternationallyWorld = 'assets/img/banners/shop-internationally/desktop_world.webp';
  @HostListener('window:resize', ['$event.target'])
  onResize() {
    this.resizeWorks();
  }
  private resizeWorks(): void {
    let windowSize = window.innerWidth;
    if (windowSize < 768) {
      this.shopInternationallyWorld = 'assets/img/banners/shop-internationally/mobile_world.webp';
      this.commercialBannerImage = 'https://d1we2hgzmci25y.cloudfront.net/assets/img/valentines_day_2024_banner_mobile.webp';
    } else {
      this.shopInternationallyWorld = 'assets/img/banners/shop-internationally/desktop_world.webp';
      this.commercialBannerImage = 'https://d1we2hgzmci25y.cloudfront.net/assets/img/valentines_day_2024_banner_desktop.webp';
    }
  }
  htmlDecode(input) {
    let e = document.createElement('textarea');
    e.innerHTML = input;
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
  }
  loginWithToken() {
    this.router.queryParams.subscribe(params => {
      if (params.token && params.userid) {
        localStorage.setItem('token', params.token);
        localStorage.setItem('userId', params.userid);
        this.signUpService.getUserByUserID(params.userid, params.token).subscribe(data => {
          this.profileDetails = data["data"];
          this.store.dispatch(AuthActions.LoginSuccess({ token: params.token, user: this.profileDetails }))
          this.route.navigate(['/'])
        })
      }
    })
  }
  ngAfterViewInit() {
    this.signUpService.getTopRatedHostList().subscribe(list => {
      this.topRatedHostList = list["data"];
      this.cdr.detectChanges();
    })
    this.signUpService.getAllCountries().subscribe(list => {
      this.allCountries = list["data"];
      this.countryTo = "TR";
      this.requestNonHostList = this.allCountries.filter(country => {
        return country.is_host === 0;
      });
      this.cdr.detectChanges();
    })

  }
  shoppingProductsRecieved = false;
  step = 1; 
  ngOnInit() {
    this.resizeWorks();
    this.initSwiper();
    if (this.isAndrodiWebview() || this.isWebview()) {
      this.platformisWebView = true;
    }
    this.store.select('auth').subscribe(state => {
      this.token = state.token;
      this.userID = state.user?.id;
      this.cdr.detectChanges();
    })
    localStorage.removeItem('confrimedCheckout');
    localStorage.removeItem('confirmedOrderCheckUrl');
    localStorage.removeItem('confirmedOrderCheckPrice');
    this.watcherService.getLang().subscribe(
      lang => {
        this.lang = lang
        this.cdr.detectChanges();
      }
    )
    this.signUpService.getBFMPuchases(this.token).subscribe(data => {
      this.bfmPurchaseList = data["data"].reverse();
      this.cdr.detectChanges();
    })
    this.loginWithToken()
    if (this.token) {
      this.signUpService.getUserByUserID(this.userID, this.token).subscribe(data => {
        this.profileDetails = data["data"];
        this.form.patchValue({ email: this.profileDetails.email })
        this.form.controls.email.disable();
        // If user session was ended in BFM Checkout Redirect user to BFM Checkout 
        let anonymous_id = localStorage.getItem('anonymous_id');
        let cart_id = localStorage.getItem('cart_id');
        let bfm_country_from = localStorage.getItem('bfm_country_from');
        let bfm_country_to = localStorage.getItem('bfm_country_to');
        let bfm_shopper_id = localStorage.getItem('bfm_shopper_id');
        let userType = data['data']['type'];
        let isPhoneVerified = data["data"]["is_phone_verified"];
        let isEmailVerified = data["data"]["is_email_verified"];
        let sessionUserId = data['data']['id'];

        // Check if user verified 
        if (anonymous_id && cart_id && userType === 1 && isPhoneVerified === 1 && isEmailVerified === 1 && sessionUserId === bfm_shopper_id) {
          localStorage.removeItem('anonymous_id');
          localStorage.removeItem('cart_id');
          localStorage.removeItem('bfm_country_from');
          localStorage.removeItem('bfm_country_to');
          localStorage.removeItem('bfm_shopper_id');
          this.route.navigate(['/', this.lang, 'bfm', 'checkout'], {
            queryParams: {
              anonymous_id: anonymous_id,
              cart_id: cart_id,
              country_from: bfm_country_from,
              country_to: bfm_country_to,
              shopper_id: bfm_shopper_id
            }
          })
        }
        // If user session was ended in BFM Checkout Redirect user to BFM Checkout 

        let orderID = localStorage.getItem('orderDetailID');
        let orderUserID = localStorage.getItem('orderDetailUserId');
        if (orderID && userType === 1 && sessionUserId === orderUserID) {
          localStorage.removeItem('orderDetailID');
          localStorage.removeItem('orderDetailUserId');
          this.route.navigate(['/', this.lang, 'member', 'order-details', orderID])
        }
      })
    }
    this.signUpService.getHostCountries().subscribe(list => {
      this.hostCountries = list["data"];
      this.hostCountriesCount = Number(localStorage.getItem('hostCountriesCount')) ?? 40; 
      // this.hostCountriesCount = this.hostCountries.length;
      this.startAnimation();
      this.countryFrom = list["data"][0]["code"];
      this.cdr.detectChanges();
    })
  }

  startAnimation() {
    const intervalId = setInterval(() => {
      if (this.hostCountriesCount === this.hostCountries.length) {
        clearInterval(intervalId);
      } else {
        this.hostCountriesCount++;
        this.cdr.detectChanges();
      }
    }, 50);
  }
  goLocation(path) {
    path = path.toLowerCase();
    if (path !== 'howitworks-for-shoppers') {
      this.route.navigate(['/', this.lang, 'shop-from', path])
    } else {
      this.route.navigate(['/', this.lang, 'howitworks-for-shoppers'])
    }
  }
  test(countryCode) {
    if (countryCode === "other") {
      $('#hostCountryRequestModal').modal('show');
      setTimeout(() => { this.countryFrom = "AU" }, 500);
    }
  }
  checkBFMTabValidations() {
    let hasError = false
    if (this.bfmMailForm.controls.bfmMail.status === "INVALID") {
      this.bfmMailForm.controls.bfmMail.markAsTouched()
      this.toastr.error("Please enter the proper product link to continue", 'Error');
      hasError = true;
    }
    return hasError;
  }
  goBFM() {
    if (this.checkBFMTabValidations() === false) {
      let userData = {
        source: "web"
      }
      this.signUpService.getAnonymousID(this.token, userData).subscribe(data => {
        this.anonymousID = data["anonymous_id"]
        let userDataShoppingCart = {
          anonymous_id: this.anonymousID
        }
        this.signUpService.getBFMShoppingCart(this.token, userDataShoppingCart).subscribe(data => {
          this.cartID = data["shopping_cart_id"]
          this.route.navigate(['/', this.lang, 'bfm'], {
            queryParams: {
              anonymous_id: this.anonymousID,
              cart_id: this.cartID,
              link: this.bfmMailForm.value.bfmMail
            }
          })
        })
      })
    }
  }
  goBFMExplanation() {
    this.route.navigate(['/', this.lang, 'bfm', 'explanation'])
  }
  checkValidations() {
    let hasError = false
    if (this.form.controls.email.status === "INVALID") {
      this.form.controls.email.markAsTouched()
      hasError = true;
    }
    return hasError;
  }
  saveHostRequest() {
    if (this.checkValidations() === false) {
      let userData = {
        email: this.form.value.email,
        country_code: this.form.value.country
      }
      this.signUpService.sendHostRequest(this.token, userData).subscribe(data => {
        if (data["result"]["status"] === 200) {
          this.toastr.success(data["result"]["message"], 'Success');
          $('#hostCountryRequestModal').modal('hide')
          if (!this.token) {
            this.form.reset()
            this.form.patchValue({ country: "AW" })
          }
          this.cdr.detectChanges();
        }
        else {
          this.toastr.error(data["result"]["message"], 'Error');
        }
      })
    }
  }
  trackByIndex(index: number): any {
    return index;
  }
  initSwiper() {



    // Main Banners Carousel

    const swiperBannerEl = document.getElementById('shippn-main-banner-swiper');
    // const buttonBannerNextEl = document.getElementById('mainBannerNext');
    // buttonBannerNextEl.addEventListener('click', () => {
    //   swiperBannerEl['swiper'].slideNext();
    // });
    // const buttonBannerPrevEl = document.getElementById('mainBannerPrev');
    // buttonBannerPrevEl.addEventListener('click', () => {
    //   swiperBannerEl['swiper'].slidePrev();
    // });
    Object.assign(swiperBannerEl, {
      slidesPerView: 1,
      spaceBetween: 0,
      slidesPerGroup: 1,
      nextButton: '#mainBannerNext',
      prevButton: '#mainBannerPrev',
      breakpoints: {
        "768": {
          slidesPerView: 1,
          spaceBetween: 0,
          slidesPerGroup: 1
        },
        "992": {
          slidesPerView: 1,
          spaceBetween: 0,
          slidesPerGroup: 1
        },
        "1200": {
          slidesPerView: 1,
          spaceBetween: 0,
          slidesPerGroup: 1
        },
      },

    });
    const swiperRouteEl = document.getElementById('shippn-rated-routes-swiper');
    const buttonNextEl = document.getElementById('topRoute-next');
    buttonNextEl.addEventListener('click', () => {
      swiperRouteEl['swiper'].slideNext();
    });
    const buttonPrevEl = document.getElementById('topRoute-prev');
    buttonPrevEl.addEventListener('click', () => {
      swiperRouteEl['swiper'].slidePrev();
    });
    Object.assign(swiperRouteEl, {
      slidesPerView: 1,
      spaceBetween: 10,
      slidesPerGroup: 1,
      nextButton: '#topRoute-next',
      prevButton: '#topRoute-prev',
      breakpoints: {
        "420": {
          slidesPerView: 1,
          spaceBetween: 10,
          slidesPerGroup: 1
        },
        "576": {
          slidesPerView: 2,
          spaceBetween: 10,
          slidesPerGroup: 2
        },
        "768": {
          slidesPerView: 2,
          spaceBetween: 15,
          slidesPerGroup: 2
        },
        "992": {
          slidesPerView: 3,
          spaceBetween: 20,
          slidesPerGroup: 3
        },
        "1024": {
          slidesPerView: 3,
          spaceBetween: 40,
          slidesPerGroup: 3
        },
        "1200": {
          slidesPerView: 4,
          spaceBetween: 50,
          slidesPerGroup: 4
        },
      },
    });
    const swiperHottestEL = document.getElementById('shippn-hottest-products-swiper');
    const buttonHottestNextEl = document.getElementById('hottestProducts-next');
    buttonHottestNextEl.addEventListener('click', () => {
      swiperHottestEL['swiper'].slideNext();
    });
    const buttonHottestPrevEl = document.getElementById('hottestProducts-prev');
    buttonHottestPrevEl.addEventListener('click', () => {
      swiperHottestEL['swiper'].slidePrev();
    });
    Object.assign(swiperHottestEL, {
      slidesPerView: 1,
      spaceBetween: 10,
      nextButton: '#topRoute-next',
      prevButton: '#topRoute-prev',
      breakpoints: {
        "420": {
          slidesPerView: 1,
          spaceBetween: 10,
          slidesPerGroup: 1
        },
        "576": {
          slidesPerView: 2,
          spaceBetween: 10,
          slidesPerGroup: 2
        },
        "768": {
          slidesPerView: 2,
          spaceBetween: 15,
          slidesPerGroup: 2
        },
        "992": {
          slidesPerView: 3,
          spaceBetween: 20,
          slidesPerGroup: 1
        },
        "1024": {
          slidesPerView: 3,
          spaceBetween: 40,
          slidesPerGroup: 3
        },
        "1200": {
          slidesPerView: 4,
          spaceBetween: 50,
          slidesPerGroup: 4
        },
      },
    });
    setTimeout(() => {
      register();
    }, 100)
  }

  goToCommercialBannerPage() {



    if (this.platformisWebView) {
      window.open('https://sale.shippn.com/valentinesday/', 'system', 'noreferrer');
    } else {
      window.open('https://sale.shippn.com/valentinesday/', '_blank', 'noreferrer');
    }


  }

  isWebview = () => {
    if (typeof window === undefined) { return false };
    let navigator: any = window.navigator;
    const standalone = navigator.standalone;
    const userAgent = navigator.userAgent.toLowerCase();
    const safari = /safari/.test(userAgent);
    const ios = /iphone|ipod|ipad/.test(userAgent);
    return ios ? !standalone && !safari : userAgent.includes('wv');
  }
  isAndrodiWebview = () => {
    let isWebView = false;
    let userAgent = navigator.userAgent;
    if (/Android/.test(userAgent)) {
      var androidVersion = parseFloat(userAgent.slice(userAgent.indexOf("Android") + 8));
      if (androidVersion >= 10) {
        isWebView = /(wv)/.test(userAgent);
      } else {
        isWebView = userAgent.includes("Version/");
      }
    }
    if (isWebView) {
      return true
    } else {
      return false;
    }
  }

  platformisWebView = false;


  arrayMaker(n){
    return Array(n)
  }
}