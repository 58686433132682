import { Component, HostListener, Inject, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SignupService } from 'src/app/services/signup.service';
import { WatcherService } from 'src/app/services/watcher.service';
import { VisitorModel } from './visitor.model';
import { DOCUMENT } from '@angular/common';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
@Component({
    selector: 'app-hi-10',
    templateUrl: './hi10.html',
    styleUrls: ['./hi10.css'],
    host: {
        '(window:resize)': 'onResize()'
    }
})
export class Hi10Component implements OnInit {
    isVisitorFromUS: Boolean = false;
    visitorCountry: string = '';
    visitorCountryName: string = '';
    visitorNationality: string = '';
    featuredCommentMember: { name: string, thumbnail: string } = {
        name: 'Member',
        thumbnail: ''
    };
    hostCountriesCount = Number(localStorage.getItem('hostCountriesCount')) ?? 40;
    spanishMembers: VisitorModel[] = [
        {
            name: "Fernando M.",
            thumbnail: 'man',
            country: 'Spain',
            country_code: 'spain',
            pp_url: 'Fernando-M-Spain',
            comment: 'Thank you very much for the fast and excellent service. Another thanks for the kind and professional live customer support.'
        },
        {
            name: "Andres P.",
            thumbnail: 'man',
            country: 'Spain',
            country_code: 'spain',
            pp_url: 'Andres-P-Spain',
            comment: 'Loved it! Clear communication, fast response, and received a picture of my items before being shipped which made me very happy!'
        },
        {
            name: "Guido N.",
            thumbnail: 'man',
            country: 'Spain',
            country_code: 'spain',
            pp_url: 'Guido-N-Spain',
            comment: 'Very happy about my first experience of this kind of service. The only note: it would be good to have an estimate in advance of how much the shipment would cost.'
        },
        {
            name: "Javier H.",
            thumbnail: 'man',
            country: 'Spain',
            country_code: 'spain',
            pp_url: 'Javier-H-Spain',
            comment: 'I wanted to get a product that is on sale only in Japan. This shop wouldn’t even accept an overseas payment! Without the unique “buy for me” service this would not be possible for me. Shippn’s host in Japan paid on my behalf and sent it over to me. Really great team. Thanks!'
        },
        {
            name: "Sandia Z.",
            thumbnail: 'woman',
            country: 'Spain',
            country_code: 'spain',
            pp_url: 'Sandia-Z-Spain',
            comment: 'Another great service from Shippn. US host Michael is an amazing person, great communication, support and help. This what you are looking for from companies like Shippn - TRUST and SUPPORT!'
        }
    ]
    frenchMembers: VisitorModel[] = [
        {
            name: "Adrienne E.",
            thumbnail: 'woman',
            country: 'France',
            country_code: 'france',
            pp_url: 'Adrienne-E-France',
            comment: 'Thank you very much for the fast and excellent service. Another thanks for the kind and professional live customer support.'
        },
        {
            name: "Cecille R.",
            thumbnail: 'woman',
            country: 'France',
            country_code: 'france',
            pp_url: 'Cecille-R-France',
            comment: 'Loved it! Clear communication, fast response, and received a picture of my items before being shipped which made me very happy!'
        },
        {
            name: "Denise G.",
            thumbnail: 'man',
            country: 'France',
            country_code: 'france',
            pp_url: 'Denise-G-France',
            comment: 'Very happy about my first experience of this kind of service. The only note: it would be good to have an estimate in advance of how much the shipment would cost.'
        },
        {
            name: "Thibaud C.",
            thumbnail: 'man',
            country: 'France',
            country_code: 'france',
            pp_url: 'Thibaud-C-France',
            comment: 'I wanted to get a product that is on sale only in Japan. This shop wouldn’t even accept an overseas payment! Without the unique “buy for me” service this would not be possible for me. Shippn’s host in Japan paid on my behalf and sent it over to me. Really great team. Thanks!'
        },
        {
            name: "Nadine B.",
            thumbnail: 'woman',
            country: 'France',
            country_code: 'france',
            pp_url: 'Nadine-B-France',
            comment: 'Another great service from Shippn. US host Michael is an amazing person, great communication, support and help. This what you are looking for from companies like Shippn - TRUST and SUPPORT!'
        }
    ]
    sgMembers: VisitorModel[] = [
        {
            name: "Kat M.",
            thumbnail: 'woman',
            country: 'Singapore',
            country_code: 'singapore',
            pp_url: 'Kat-M-Singapore',
            comment: 'Thank you very much for the fast and excellent service. Another thanks for the kind and professional live customer support.'
        },
        {
            name: "Timothy L.",
            thumbnail: 'man',
            country: 'Singapore',
            country_code: 'singapore',
            pp_url: 'Timothy-L-Singapore',
            comment: 'Loved it! Clear communication, fast response, and received a picture of my items before being shipped which made me very happy!'
        },
        {
            name: "Lin H.",
            thumbnail: 'woman',
            country: 'Singapore',
            country_code: 'singapore',
            pp_url: 'Lin-H-Singapore',
            comment: 'Very happy about my first experience of this kind of service. The only note: it would be good to have an estimate in advance of how much the shipment would cost.'
        },
        {
            name: "Paul C.",
            thumbnail: 'man',
            country: 'Singapore',
            country_code: 'singapore',
            pp_url: 'Paul-C-Singapore',
            comment: 'I wanted to get a product that is on sale only in Japan. This shop wouldn’t even accept an overseas payment! Without the unique “buy for me” service this would not be possible for me. Shippn’s host in Japan paid on my behalf and sent it over to me. Really great team. Thanks!'
        },
        {
            name: "Shaun P.",
            thumbnail: 'man',
            country: 'Singapore',
            country_code: 'singapore',
            pp_url: 'Shaun-P-Singapore',
            comment: 'Another great service from Shippn. US host Michael is an amazing person, great communication, support and help. This what you are looking for from companies like Shippn - TRUST and SUPPORT!'
        }
    ]
    deMembers: VisitorModel[] = [
        {
            name: "Thomas K.",
            thumbnail: 'man',
            country: 'Germany',
            country_code: 'germany',
            pp_url: 'Thomas-K-Germany',
            comment: 'Thank you very much for the fast and excellent service. Another thanks for the kind and professional live customer support.'
        },
        {
            name: "Hans S.",
            thumbnail: 'man',
            country: 'Germany',
            country_code: 'germany',
            pp_url: 'Hans-S-Germany',
            comment: 'Loved it! Clear communication, fast response, and received a picture of my items before being shipped which made me very happy!'
        },
        {
            name: "Susi D.",
            thumbnail: 'woman',
            country: 'Germany',
            country_code: 'germany',
            pp_url: 'Susi-D-Germany',
            comment: 'Very happy about my first experience of this kind of service. The only note: it would be good to have an estimate in advance of how much the shipment would cost.'
        },
        {
            name: "Florian K.",
            thumbnail: 'woman',
            country: 'Germany',
            country_code: 'germany',
            pp_url: 'Florian-K-Germany',
            comment: 'I wanted to get a product that is on sale only in Japan. This shop wouldn’t even accept an overseas payment! Without the unique “buy for me” service this would not be possible for me. Shippn’s host in Japan paid on my behalf and sent it over to me. Really great team. Thanks!'
        },
        {
            name: "Sarah Y.",
            thumbnail: 'man',
            country: 'Germany',
            country_code: 'germany',
            pp_url: 'Sarah-Y-Germany',
            comment: 'Another great service from Shippn. US host Michael is an amazing person, great communication, support and help. This what you are looking for from companies like Shippn - TRUST and SUPPORT!'
        }
    ]
    ilMembers: VisitorModel[] = [
        {
            name: "Lior R.",
            thumbnail: 'man',
            country: 'Israel',
            country_code: 'israel',
            pp_url: 'Lior-R-Israel',
            comment: 'Thank you very much for the fast and excellent service. Another thanks for the kind and professional live customer support.'
        },
        {
            name: "Ofir S.",
            thumbnail: 'man',
            country: 'Israel',
            country_code: 'israel',
            pp_url: 'Ofir-S-Israel',
            comment: 'Loved it! Clear communication, fast response, and received a picture of my items before being shipped which made me very happy!'
        },
        {
            name: "Tal I.",
            thumbnail: 'woman',
            country: 'Israel',
            country_code: 'israel',
            pp_url: 'Tal-I-Israel',
            comment: 'Very happy about my first experience of this kind of service. The only note: it would be good to have an estimate in advance of how much the shipment would cost.'
        },
        {
            name: "Noa A.",
            thumbnail: 'woman',
            country: 'Israel',
            country_code: 'israel',
            pp_url: 'Noa-A-Israel',
            comment: 'I wanted to get a product that is on sale only in Japan. This shop wouldn’t even accept an overseas payment! Without the unique “buy for me” service this would not be possible for me. Shippn’s host in Japan paid on my behalf and sent it over to me. Really great team. Thanks!'
        },
        {
            name: "Dana G.",
            thumbnail: 'man',
            country: 'Israel',
            country_code: 'israel',
            pp_url: 'Dana-G-Israel',
            comment: 'Another great service from Shippn. US host Michael is an amazing person, great communication, support and help. This what you are looking for from companies like Shippn - TRUST and SUPPORT!'
        }
    ]
    saMembers: VisitorModel[] = [
        {
            name: "Mariam A.",
            thumbnail: 'woman',
            country: 'Saudi Arabia',
            country_code: 'saudi_arabia',
            pp_url: 'Mariam-A-Saudi-Arabia',
            comment: 'Thank you very much for the fast and excellent service. Another thanks for the kind and professional live customer support.'
        },
        {
            name: "Omar S.",
            thumbnail: 'man',
            country: 'Saudi Arabia',
            country_code: 'saudi_arabia',
            pp_url: 'Omar-S-Saudi-Arabia',
            comment: 'Loved it! Clear communication, fast response, and received a picture of my items before being shipped which made me very happy!'
        },
        {
            name: "Hakeem T.",
            thumbnail: 'woman',
            country: 'Saudi Arabia',
            country_code: 'saudi_arabia',
            pp_url: 'Hakeem-T-Saudi-Arabia',
            comment: 'Very happy about my first experience of this kind of service. The only note: it would be good to have an estimate in advance of how much the shipment would cost.'
        },
        {
            name: "Bassam A.",
            thumbnail: 'woman',
            country: 'Saudi Arabia',
            country_code: 'saudi_arabia',
            pp_url: 'Bassam-A-Saudi-Arabia',
            comment: 'I wanted to get a product that is on sale only in Japan. This shop wouldn’t even accept an overseas payment! Without the unique “buy for me” service this would not be possible for me. Shippn’s host in Japan paid on my behalf and sent it over to me. Really great team. Thanks!'
        },
        {
            name: "Khalil A.",
            thumbnail: 'man',
            country: 'Saudi Arabia',
            country_code: 'saudi_arabia',
            pp_url: 'Khalil-A-Saudi-Arabia',
            comment: 'Another great service from Shippn. US host Michael is an amazing person, great communication, support and help. This what you are looking for from companies like Shippn - TRUST and SUPPORT!'
        }
    ]
    malaysianMembers: VisitorModel[] = [
        {
            name: "Bella N.",
            thumbnail: 'woman',
            country: 'Malaysia', 
            country_code: 'malaysia',
            pp_url: 'Bella-N-Malaysia',
            comment: 'Thank you very much for the fast and excellent service. Another thanks for the kind and professional live customer support.'
        },
        {
            name: "Chris M.",
            thumbnail: 'man',
            country: 'Malaysia',
            country_code: 'extra',
            pp_url: 'user-malaysia-01',
            comment: 'Loved it! Clear communication, fast response, and received a picture of my items before being shipped which made me very happy!'
        },
        {
            name: "Nick S.",
            thumbnail: 'man',
            country: 'Malaysia',
            country_code: 'extra',
            pp_url: 'user-malaysia-02',
            comment: 'Very happy about my first experience of this kind of service. The only note: it would be good to have an estimate in advance of how much the shipment would cost.'
        },
        {
            name: "Yvonne G.",
            thumbnail: 'woman',
            country: 'Malaysia',
            country_code: 'malaysia',
            pp_url: 'Yvonne-G-Malaysia',
            comment: 'I wanted to get a product that is on sale only in Japan. This shop wouldn’t even accept an overseas payment! Without the unique “buy for me” service this would not be possible for me. Shippn’s host in Japan paid on my behalf and sent it over to me. Really great team. Thanks!'
        },
        {
            name: "Fang Chia S.",
            thumbnail: 'woman',
            country: 'Malaysia',
            country_code: 'malaysia',
            pp_url: 'Fang-Chia-S-Malaysia',
            comment: 'Another great service from Shippn. US host Michael is an amazing person, great communication, support and help. This what you are looking for from companies like Shippn - TRUST and SUPPORT!'
        }
    ]
    dutchMember: VisitorModel[] = [
        {
            name: "Mylene D.",
            thumbnail: 'woman',
            country: 'Netherlands',
            country_code: 'netherlands',
            pp_url: 'Mylne-D-Netherlands',
            comment: 'Thank you very much for the fast and excellent service. Another thanks for the kind and professional live customer support.'
            
        },
        {
            name: "Carmel D.",
            thumbnail: 'woman',
            country: 'Netherlands', 
            country_code: 'netherlands',
            pp_url: 'Carmel-D-Netherlands',
            comment: 'Loved it! Clear communication, fast response, and received a picture of my items before being shipped which made me very happy!'
        },
        {
            name: "Joost V.",
            thumbnail: 'man',
            country: 'Netherlands',
            country_code: 'extra',
            pp_url: 'user-netherlands',
            comment: 'Very happy about my first experience of this kind of service. The only note: it would be good to have an estimate in advance of how much the shipment would cost.'
        },
        {
            name: "Nelleke P.",
            thumbnail: 'woman',
            country: 'Netherlands',
            country_code: 'netherlands',
            pp_url: 'Nelleke-P-Netherlands',
            comment: 'I wanted to get a product that is on sale only in Japan. This shop wouldn’t even accept an overseas payment! Without the unique “buy for me” service this would not be possible for me. Shippn’s host in Japan paid on my behalf and sent it over to me. Really great team. Thanks!'
        },
        {
            name: "Joyce S.",
            thumbnail: 'woman',
            country: 'Netherlands', 
            country_code: 'netherlands',
            pp_url: 'Joyce-S-Netherlands',
            comment: 'Another great service from Shippn. US host Michael is an amazing person, great communication, support and help. This what you are looking for from companies like Shippn - TRUST and SUPPORT!'
        }
    ]
    ukMembers: VisitorModel[] = [
        {
            name: "Anne P.",
            thumbnail: 'woman',
            country: 'United Kingdom',
            country_code: 'uk',
            pp_url: 'Anne-P-UK',
            comment: 'Thank you very much for the fast and excellent service. Another thanks for the kind and professional live customer support.'
        },
        {
            name: "Noah B.",
            thumbnail: 'man',
            country: 'United Kingdom',
            country_code: 'uk',
            pp_url: 'Noah-B-UK',
            comment: 'Loved it! Clear communication, fast response, and received a picture of my items before being shipped which made me very happy!'
        },
        {
            name: "Kirsten T.",
            thumbnail: 'woman',
            country: 'United Kingdom', 
            country_code: 'uk',
            pp_url: 'Kirsten-T-UK',
            comment: 'Very happy about my first experience of this kind of service. The only note: it would be good to have an estimate in advance of how much the shipment would cost.'
        },
        {
            name: "Lewis S.",
            thumbnail: 'man',
            country: 'United Kingdom',
            country_code: 'uk',
            pp_url: 'Lewis-S-UK',
            comment: 'I wanted to get a product that is on sale only in Japan. This shop wouldn’t even accept an overseas payment! Without the unique “buy for me” service this would not be possible for me. Shippn’s host in Japan paid on my behalf and sent it over to me. Really great team. Thanks!'
        },
        {
            name: "Ross L.",
            thumbnail: 'man',
            country: 'United Kingdom',
            country_code: 'uk',
            pp_url: 'Ross-L-UK',
            comment: 'Another great service from Shippn. US host Michael is an amazing person, great communication, support and help. This what you are looking for from companies like Shippn - TRUST and SUPPORT!'
        }
    ]
    usMembers: VisitorModel[] = [
        {
            name: "Anne P.",
            thumbnail: 'woman',
            country_code: 'uk',
            pp_url: 'Kirsten-T-UK',
            comment: 'Thank you very much for the fast and excellent service. Another thanks for the kind and professional live customer support.'

        },
        {
            name: 'John B.',
            thumbnail: 'man',
            country: 'U.S.A.',
            country_code: 'extra',
            pp_url: 'user-us-01',
            comment: 'Loved it! Clear communication, fast response, and received a picture of my items before being shipped which made me very happy!'
        },
        {
            name: 'Kristen T.',
            thumbnail: 'woman',
            country: 'United Kingdom',
            country_code: 'uk',
            pp_url: 'Kirsten-T-UK',
            comment: 'Very happy about my first experience of this kind of service. The only note: it would be good to have an estimate in advance of how much the shipment would cost.'
        },
        {
            name: 'Erin W.',
            thumbnail: 'woman',
            country: 'Australia', 
            country_code: 'australia',
            pp_url: 'Erin-W-Australia',
            comment: 'I wanted to get a product that is on sale only in Japan. This shop wouldn’t even accept an overseas payment! Without the unique “buy for me” service this would not be possible for me. Shippn’s host in Japan paid on my behalf and sent it over to me. Really great team. Thanks!'
        },
        {
            name: 'Emily M.',
            thumbnail: 'woman',
            country: 'Canada',
            country_code: 'canada',
            pp_url: 'Emily-M-Canada',
            comment: 'Another great service from Shippn. US host Michael is an amazing person, great communication, support and help. This what you are looking for from companies like Shippn - TRUST and SUPPORT!'
        }
    ]
    members: VisitorModel[] = [
        {
            name: "Anne P.",
            thumbnail: 'woman',
            country_code: 'uk',
            pp_url: 'Kirsten-T-UK',
            comment: 'Thank you very much for the fast and excellent service. Another thanks for the kind and professional live customer support.'

        },
        {
            name: 'Noah B.',
            thumbnail: 'man',
            country: 'U.S.A.',
            country_code: 'uk',
            pp_url: 'Noah-B-UK',
            comment: 'Loved it! Clear communication, fast response, and received a picture of my items before being shipped which made me very happy!'
        },
        {
            name: 'Kristen T.',
            thumbnail: 'woman',
            country: 'United Kingdom',
            country_code: 'uk',
            pp_url: 'Kirsten-T-UK',
            comment: 'Very happy about my first experience of this kind of service. The only note: it would be good to have an estimate in advance of how much the shipment would cost.'
        },
        {
            name: 'Lewis S.',
            thumbnail: 'man',
            country: 'Australia', 
            country_code: 'uk',
            pp_url: 'Lewis-S-UK',
            comment: 'I wanted to get a product that is on sale only in Japan. This shop wouldn’t even accept an overseas payment! Without the unique “buy for me” service this would not be possible for me. Shippn’s host in Japan paid on my behalf and sent it over to me. Really great team. Thanks!'
        },
        {
            name: 'Ross L.',
            thumbnail: 'man',
            country: 'Canada',
            country_code: 'uk',
            pp_url: 'Ross-L-UK',
            comment: 'Another great service from Shippn. US host Michael is an amazing person, great communication, support and help. This what you are looking for from companies like Shippn - TRUST and SUPPORT!'
        }
    ]
    comments: Array<string> = [
        "Thank you very much for the fast and excellent service. Another thanks for the kind and professional live customer support.",
        "Loved it! Clear communication, fast response, and received a picture of my items before being shipped which made me very happy!",
        "Very happy about my first experience of this kind of service. The only note: it would be good to have an estimate in advance of how much the shipment would cost.",
        "I wanted to get a product that is on sale only in Japan. This shop wouldn’t even accept an overseas payment! Without the unique “buy for me” service this would not be possible for me. Shippn’s host in Japan paid on my behalf and sent it over to me. Really great team. Thanks!",
        "Another great service from Shippn. US host Michael is an amazing person, great communication, support and help. This what you are looking for from companies like Shippn - TRUST and SUPPORT!"
    ]
    featuredComment_Comment: string = '';
    discountcodes: Array<string> = [
        "HELLOES10",
        "HELLOFR10",
        "HELLODE10",
        "HELLOSA10",
        "HELLOIL10",
        "HELLOSG10",
        "HELLOMY10",
        "HELLONL10",
        "HELLOUK10",
        "HELLOUS10"
    ]
    discountcode: string = '';
    @HostListener('window:resize', ['$event.target'])
    onResize() {
        this.resizeWorks();
    }
    bgColor = "#dedede";
    bannerImageUrL: SafeUrl;
    private resizeWorks(): void {
        let windowSize = window.innerWidth;
        if (windowSize < 768) {
            this.ngZone.run(() => {
                this.bannerImageUrL =this.sanitizer.bypassSecurityTrustResourceUrl('../../../assets/img/landing-page/shippn_hello10-mobile.webp');
                this.bgColor = '#e2e2e2';
            })
            
        } else {
            this.ngZone.run(()=> {
                this.bannerImageUrL = this.sanitizer.bypassSecurityTrustResourceUrl('../../../assets/img/landing-page/shippn_web_hello10-web.webp')
                this.bgColor = '#dedede';
            })
            
        }
    }

    constructor(
        @Inject(DOCUMENT) private document: Document,
        public signUpService: SignupService,
        public router: Router,
        private watcherService: WatcherService,
        private sanitizer: DomSanitizer,
        private ngZone: NgZone
    ) {
        this.signUpService.getUserIPData().subscribe(data => {
            this.visitorCountry = data['country'] === 'GB' ? 'UK' : data['country'];
            this.document.documentElement.lang = this.visitorCountry.toLowerCase();
            switch (this.visitorCountry) {
                case 'ES':
                    this.visitorCountryName = 'Spain';
                    this.visitorNationality = 'Spanish';
                    this.featuredCommentMember = this.spanishMembers[0];
                    this.members = this.spanishMembers;
                    this.discountcode = this.discountcodes[0];
                    break;
                case 'FR':
                    this.visitorCountryName = 'France';
                    this.visitorNationality = 'French';
                    this.featuredCommentMember = this.spanishMembers[0];
                    this.members = this.frenchMembers;
                    this.discountcode = this.discountcodes[1];
                    break;
                case 'DE':
                    this.visitorCountryName = 'Germany';
                    this.visitorNationality = 'German';
                    this.featuredCommentMember = this.deMembers[0];
                    this.members = this.deMembers;
                    this.discountcode = this.discountcodes[2];
                    break;
                case 'SA':
                    this.visitorCountryName = 'Saudi Arabia';
                    this.visitorNationality = 'Arabic';
                    this.featuredCommentMember = this.saMembers[0];
                    this.members = this.saMembers;
                    this.discountcode = this.discountcodes[3];
                    break;
                case 'IL':
                    this.visitorCountryName = 'Israel';
                    this.visitorNationality = 'Israeli';
                    this.featuredCommentMember = this.ilMembers[0];
                    this.members = this.ilMembers;
                    this.discountcode = this.discountcodes[4];
                    break;
                case 'SG':
                    this.visitorCountryName = 'Singapore';
                    this.visitorNationality = 'Singaporean';
                    this.featuredCommentMember = this.sgMembers[0];
                    this.members = this.sgMembers;
                    this.discountcode = this.discountcodes[5];
                    break;
                case 'MY':
                    this.visitorCountryName = 'Malaysia';
                    this.visitorNationality = 'Malaysian';
                    this.featuredCommentMember = this.malaysianMembers[0];
                    this.members = this.malaysianMembers;
                    this.discountcode = this.discountcodes[6];
                    break;
                case 'NL':
                    this.visitorCountryName = 'Netherlands';
                    this.visitorNationality = 'Dutch';
                    this.featuredCommentMember = this.dutchMember[0];
                    this.members = this.dutchMember;
                    this.discountcode = this.discountcodes[7];
                    break;
                case 'UK':
                    this.visitorCountryName = 'United Kingdom';
                    this.visitorNationality = 'English';
                    this.featuredCommentMember = this.ukMembers[0];
                    this.members = this.ukMembers;
                    this.discountcode = this.discountcodes[8];
                    break;
                case 'UK':
                    this.visitorCountryName = 'United Kingdom';
                    this.visitorNationality = 'English';
                    this.featuredCommentMember = this.ukMembers[0];
                    this.members = this.ukMembers;
                    this.discountcode = this.discountcodes[8];
                    break;
                case 'US':
                    this.isVisitorFromUS = true;
                    this.visitorNationality = 'English';
                    this.featuredCommentMember = this.usMembers[0];
                    this.members = this.usMembers;
                    this.discountcode = this.discountcodes[9];
                default:
                    this.isVisitorFromUS = true;
                    this.visitorNationality = 'English';
                    this.featuredCommentMember = this.usMembers[0];
                    this.members = this.usMembers;
                    this.discountcode = this.discountcodes[9];
                    break;
            }
        })
        this.featuredComment_Comment = this.comments[0];
    }

    lang;
    ngOnInit(): void {
        this.onResize();
        this.watcherService.getLang().subscribe(
            lang => {
                this.lang = lang;
            }
        )
        this.hostCountriesCount = Number(localStorage.getItem('hostCountriesCount'));
        if(!this.hostCountriesCount){
            this.signUpService.getHostCountries().subscribe(list => {
                this.hostCountriesCount = list["data"].length;
            })
        }
    }
}