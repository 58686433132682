export const shippnpilotReviews = [
    {
        review_rating: 5,
        review_title: "Great service",
        review_content: "I didn't know such a service existed and when I found it, I was skeptical at first. But the whole process was seamless, the website was clear and informative and Stanislav (who collected and shipped the items) kept me up to date on every step. Got here quickly too. Very satisfied!",
        review_user: "Nicholas",
        review_country: "United States of America",
        review_country_flag: "https://www.shippn.com/assets/img/flags/united-states-of-america.png"
    },
    {
        review_rating: 5,
        review_title: "Three orders all great!",
        review_content: "I'm a collector of a particular brand of product from around the world. Since discovering shippn two months ago, I've been able to shop for my favorite things in new markets such as Australia and South Africa. I've placed three orders with Shippn so far, and the fragile and hard to find items have all reached me quickly and in perfect condition. I've worked with two separate hosts on the three orders and both were a pleasure to work with and very responsive.",
        review_user: "Anita",
        review_country: "United States of America",
        review_country_flag: "https://www.shippn.com/assets/img/flags/united-states-of-america.png"
    },
    {
        review_rating: 5,
        review_title: "This is my 3rd experience with Shippn",
        review_content: "This is my 3rd experience with Shipping and it went marvelously as it was the case for my two former experiences with them. I trusted in the same host -- Mr. Kelby Cody -- and I'm so happy to have met him as a host. Kelby is such a great guy you can trust in. He always does the right thing, the fast and easy way. Kelby never forgets to get you updated about what's going on with your purchase. I really recommend Kelby and of course Shippn.",
        review_user: "A. Brahm",
        review_country: "Belgium",
        review_country_flag: "https://www.shippn.com/assets/img/flags/belgium.png"
    },
    {
        review_rating: 5,
        review_title: "Brilliant service from start to finish.",
        review_content: "I was a little dubious to start with. But I could not find the item I wanted anywhere but Australia and they would not deliver to the UK. So I tried this service, fantastic. They kept me updated all the way through and I received my item in less than 2 weeks. I would definitely use them again.",
        review_user: "Elaine",
        review_country: "United Kingdom",
        review_country_flag: "https://www.shippn.com/assets/img/flags/united-kingdom.png"
    },
    {
        review_rating: 5,
        review_title: "Absolutely great experience",
        review_content: 'Absolutely great experience, top service - the best customer experience i have ever had. I came to Shippn" with a very difficult task, to secure an expensive (4000 USD) high demand product on launch day in the US and to ship it to Germany as fast as possible. Despite all difficulties, they secured me the product one minute after the presale opened (it was 5AM in the US at that moment), carried me through all further steps to prepare and get the product delivered to Germany.',
        review_user: "Daniil",
        review_country: "Germany",
        review_country_flag: "https://www.shippn.com/assets/img/flags/germany.png"
    }, 
    {
        review_rating: 5,
        review_title: "Perfect",
        review_content: 'Great! Recieved my fabrics etc in perfect state from Indonesia to the Netherlands. The Host (Salsabila) was very kind and updated me by every step. I absolutely will make use of this service again when needed :)',
        review_user: "TjadaL",
        review_country: "Netherlands",
        review_country_flag: "https://www.shippn.com/assets/img/flags/netherlands.png"
    },
    {
        review_rating: 5,
        review_title: "Best service ever- fast and easy tracking!",
        review_content: 'I’m in Japan and the size for the boots I needed to snowboard was sold out completely even in the states so I am very happy with my Shippn host who helped me get what I needed so I can go snowboarding! Fast, quick and easy process!',
        review_user: "Jee",
        review_country: "Japan",
        review_country_flag: "https://www.shippn.com/assets/img/flags/japan.png"
    }, 
    {
        review_rating: 5,
        review_title: "Excellent service!",
        review_content: `I live in Canada and wanted an item on the Polish site, Allegro Lokalnie. Previously I had used another service which required delivery of the purchased item to their warehouse. But this new seller would only deliver to a locker box. So I found Shippn with a Google search and it worked out great! The Host picked up the item at a locker box and shipped it to me. Shippn's communications were superb. The estimated shipping costs were communicated in advance so I knew what I was getting into.`,
        review_user: "James",
        review_country: "Canada",
        review_country_flag: "https://www.shippn.com/assets/img/flags/canada.png"
    }
]