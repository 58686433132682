import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SignupService } from 'src/app/services/signup.service';
import { WatcherService } from 'src/app/services/watcher.service';
import { Store } from '@ngrx/store';
import * as fromApp from './../../store/app.reducer';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
declare var $;
@Component({
  selector: 'app-sticky-action-bar',
  templateUrl: './sticky-action-bar.component.html',
  styleUrls: ['./sticky-action-bar.component.css']
})
export class StickyActionBarComponent implements OnInit {
  token;
  userID;
  cachedUser;
  loggedInUserType = 1;
  isUserLogin: boolean = false;
  onDashboard: boolean = false;
  shoppingCartItems;
  isthereBfmBasket;
  bfmUrlAnonymousId;
  bfmUrlCartId;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    public signUpService: SignupService,
    private watcherService: WatcherService,
    private router: Router,
    private store: Store<fromApp.AppState>,
    private toastr: ToastrService,
  ) { }
  lang;
  hostCountries = [];
  countryFrom;
  hello10Variant: boolean = false;
  ngOnInit(): void {
    this.watcherService.getLang().subscribe(
      lang => {
        this.lang = lang;
      }
    )
    this.store.select('auth').subscribe(state => {
      this.token = state.token;
      this.isUserLogin = state.isAuthenticated;
      this.cachedUser = state.user;
      this.userID = state.user?.id;
      this.loggedInUserType = state.user?.type ? state.user?.type : 1;
    })
    this.router.events.forEach(item => {
      if (item instanceof NavigationEnd) {
        if (item.url.includes('member')) {
          this.onDashboard = true;
        } else {
          this.onDashboard = false;
        }
        if(item.url.includes('hi10')){
          this.hello10Variant = true;
        } else {
          this.hello10Variant = false;
        }
      }
    })
    this.store.select('shoppingCart').subscribe(state => {

      if (state.status !== 'CLOSED') {
        this.shoppingCartItems = state.bfm_items;
        if (state.anonymous_id !== '') {
          this.isthereBfmBasket = true;
          this.bfmUrlAnonymousId = state.anonymous_id;
          this.bfmUrlCartId = state.shopping_cart_id;
        }
      } else {
        this.isthereBfmBasket = false;
        this.shoppingCartItems = [];
      }




    })
  }
  openCreateOrderModal() {

    if(this.loggedInUserType == 5){
      this.router.navigate(['/', this.lang, 'b2b', 'shipment-create'])
    }
    if (this.loggedInUserType == 2) {
      if (this.document.getElementById("createOrderModalTriggerSticky")) {
        this.document.getElementById("createOrderModalTriggerSticky").click()
      }
    }

    if (this.loggedInUserType == 1) {
      if (this.document.getElementById("getNewAdressModalTriggerSticky")) {
        this.document.getElementById("getNewAdressModalTriggerSticky").click()
      }
    }
  }
  goToBasket() {

    if (this.bfmUrlAnonymousId && this.bfmUrlCartId) {
      this.router.navigate(['/', this.lang, 'bfm'], {
        queryParams: {
          anonymous_id: this.bfmUrlAnonymousId,
          cart_id: this.bfmUrlCartId
        }
      })
    } else {
      this.router.navigate(['/', this.lang, 'bfm'])
    }

  }
  goToSignUp() {
    this.router.navigate(['/', this.lang, 'signup'])
  }
  openWp() {
    const url = 'http://wa.me/18627547446'
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }
  getNewAdressCountryFrom;
  getNewAddressCountry(countryCode) {
    this.countryFrom = countryCode;
    this.getNewAdressCountryFrom = this.hostCountries.find(country => {
      return country.code == countryCode;
    })
  }

  closeShopFromModal() {
    $('#getNewAdressModal').modal('hide')
  }

  orderCreateForm = new UntypedFormGroup({
    shopperName: new UntypedFormControl(''),
    orderNumber: new UntypedFormControl('')
  })

  searchAndCreateOrder() {

    var userData = {
      order_number: this.orderCreateForm.value.orderNumber,
      shopper_name: this.orderCreateForm.value.shopperName,
      shopper_id: ""
    }

    this.signUpService.hostCreateOrder(this.token, userData, this.userID).subscribe(data => {
      if (data["result"]["status"] == 200) {

        this.toastr.success(data["result"]["message"], 'Success');
        $('#createOrderModal').modal('hide')
        this.orderCreateForm.reset()
      }
      else {
        this.toastr.error(data["result"]["message"], 'Error');
      }
    })

  }
}