import { Component, Input, OnInit, OnChanges, ViewChild, ElementRef, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { SignupService } from 'src/app/services/signup.service';
import { WatcherService } from 'src/app/services/watcher.service';
import { AppState } from 'src/app/store/app.reducer';
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'shippn-messages',
  templateUrl: './messages-directive.component.html',
  styleUrls: ['./messages-directive.component.css'],
  encapsulation: ViewEncapsulation.Emulated
})
export class MessagesDirectiveComponent implements OnInit, OnChanges, AfterContentChecked {

  @ViewChild('scrollMe') private myScrollContainer: ElementRef;


  // SHPN-5502
  isShopper = false;
  checkboxControl = new UntypedFormGroup({
    sendCopyCheckbox: new UntypedFormControl(false)
  })

  memberDetails;
  userID;
  token;
  messageData;
  messageType = null
  messageAreaType = 0
  messageAreaTypeText = ""
  messageTypeText = ""
  textArea = "";
  textAreaPlaceholder = "Please select the tab for the recipient you want to send a message from above"
  //conversationID = "61923800fd26fa03bf94573e"

  @Input() conversationID;
  @Input() orderType;
  @Input() orderDate;
  @Input() orderNumber;
  @Input() orderStatus;

  showDragDropArea = false;

  files: File[] = [];

  constructor(
    public signUpService: SignupService,
    private toastr: ToastrService,
    private cdsref: ChangeDetectorRef,
    private watcherService: WatcherService,
    private store: Store<AppState>
  ) {


  }

  lang;
  showHostLocalTime = false;
  ngOnInit(): void {
    this.store.select('auth').subscribe(state => {
      this.token = state.token;
      this.userID = state.user?.id;
    })
    this.watcherService.getLang().subscribe(
      lang => {
        this.lang = lang;
      }
    )
    this.messageData = [];
    if (this.token && this.userID) {
      this.signUpService.getUserByUserID(this.userID, this.token).subscribe(data => {
        this.memberDetails = data["data"];
        if (this.memberDetails?.type == 1) {
          this.showHostLocalTime = true;
          this.messageTypeText = "Send message to Host";
          this.messageAreaTypeText = "Messages with Host";
          if (this.orderType != 'bfmOrder') {
            this.messageType = 1;
            this.textAreaPlaceholder = "Your message will be sent to Host"
            this.isShopper = true;
          } else {
            this.isShopper = false;
          }

        }
        else if (this.memberDetails?.type == 2) {
          this.showHostLocalTime = false;
          this.messageTypeText = "Send message to Shopper"
          this.messageAreaTypeText = "Messages with Shopper"
        }
      })
    }
    if (this.conversationID && this.token) {
      this.signUpService.getMessages(this.token, this.conversationID).subscribe(data => {
        this.messageData = data["data"]["messages"];
        let hostGMTTz = data['data']['host_tz'];
        if(hostGMTTz){
          this.hostTime = this.getHostTime(hostGMTTz);
        }
        setTimeout(() => {
          this.scrollintoview();
        }, 0)
      })
    } 
  }
  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollBottom = this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) { }
  }
  scrollintoview() {
    setTimeout(() => {
      let message = window.document.querySelectorAll('.message-container-message-box');
      let el = message[message.length - 1];
      if (el) {
        el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'center' });
      } else {
        return
      }
    }, 200)

  }
  ngAfterContentChecked(): void {
    this.cdsref.detectChanges();
  }
  hostTime;
  ngOnChanges() {
    this.messageData = [];
    if (this.token && this.userID) {
      this.signUpService.getUserByUserID(this.userID, this.token).subscribe(data => {
        this.memberDetails = data["data"];
        if (this.memberDetails?.type == 1) {
          this.messageTypeText = "Send message to Host";
          this.messageAreaTypeText = "Messages with Host";
          this.showHostLocalTime = true;
          if (this.orderType != 'bfmOrder') {
            this.messageType = 1;
            this.textAreaPlaceholder = "Your message will be sent to Host"
            this.isShopper = true;
          } else {
            this.isShopper = false;
          }

        }
        else if (this.memberDetails?.type == 2) {
          this.showHostLocalTime = false;
          this.messageTypeText = "Send message to Shopper"
          this.messageAreaTypeText = "Messages with Shopper"
        }
      })
    }
    if (this.conversationID && this.token) {
      this.signUpService.getMessages(this.token, this.conversationID).subscribe(data => {
        this.messageData = data["data"]["messages"];
        let hostGMTTz = data['data']['host_tz'];
        if(hostGMTTz){
          this.hostTime = this.getHostTime(hostGMTTz)
        } else{
          this.showHostLocalTime = false;
        }
        setTimeout(() => {
          this.scrollintoview();
        }, 0)
      })
    }

  }

  sendCopyCheckboxChange() {
    if (this.checkboxControl.value.sendCopyCheckbox == true) {
      this.textAreaPlaceholder = "Your message will be sent to Host & Shippn Support"


      if (this.memberDetails?.type == 1) {
        this.textAreaPlaceholder = "Your message will be sent to Host & Shippn Support"
      }
      else if (this.memberDetails?.type == 2) {
        this.textAreaPlaceholder = "Your message will be sent to Shopper"
      }
    } else {
      if (this.memberDetails?.type == 1) {
        this.textAreaPlaceholder = "Your message will be sent to Host"
      }
      else if (this.memberDetails?.type == 2) {
        this.textAreaPlaceholder = "Your message will be sent to Shopper"
      }
    }
  }

  onSelect(event) {

    if (this.checkFileValidations(event.addedFiles[0]) == false) {

      var reader = new FileReader();
      reader.readAsDataURL(event.addedFiles[0]);
      reader.onload = () => {

        event.addedFiles[0].base64 = reader.result;
      };
      reader.onerror = function (error) {

      };

      this.files.push(...event.addedFiles);
    }


  }

  onRemove(event) {

    this.files.splice(this.files.indexOf(event), 1);
  }

  changeDragDropAreaStatus() {
    if (this.showDragDropArea == false) {
      this.showDragDropArea = true;
    }
    else {
      this.showDragDropArea = false
    }
  }

  changeMessageType(type) {

    if (type == 1) {
      this.messageType = 1
      if (this.memberDetails?.type == 1) {
        this.textAreaPlaceholder = "Your message will be sent to Host"
      }
      else if (this.memberDetails?.type == 2) {
        this.textAreaPlaceholder = "Your message will be sent to Shopper"
      }
    }
    else if (type == 2) {
      this.messageType = 2
      this.textAreaPlaceholder = "Your message will be sent to Shippn Support"
    }

  }

  checkFileValidations(file) {

    var hasError = false

    if (file.type != 'image/jpg' && file.type != 'image/png' && file.type != 'application/pdf' && file.type != 'image/jpeg' && file.type != 'image/bmp') {
      this.toastr.error("We support only jpg, jpeg, png, bmp and pdf files up to 10 MB with a maximum of 5 files in total", 'Error');
      hasError = true;
    }

    if (file.size > 10385760) {
      this.toastr.error("Upload failed. Please upload a file that meets the specified standards", 'Error');
      hasError = true;
    }

    if (this.files.length > 4) {
      this.toastr.error("We support only jpg, jpeg, png, bmp and pdf files up to 10 MB with a maximum of 5 files in total", 'Error');
      hasError = true;
    }

    return hasError;
  }

  checkValidations() {
    var hasError = false

    if (this.messageType == null) {
      this.toastr.error("Please select the tab for the recipient you want to send a message from above", 'Error');
      hasError = true;
    }

    else if (this.files.length == 0 && !this.textArea) {
      this.toastr.error("Select a file or write your message", 'Error');
      hasError = true;
    }

    var totalfileSize = 0;
    for (let index = 0; index < this.files.length; index++) {
      totalfileSize = totalfileSize + this.files[index].size
    }

    if (totalfileSize > 26214400) {
      this.toastr.error("We support only jpg, jpeg, png, bmp and pdf files up to 10 MB with a maximum of 5 files in total", 'Error');
      hasError = true;
    }

    return hasError;
  }

  sendMessage() {
    if (this.checkValidations() == false) {

      var attachmentArray = []

      for (let index = 0; index < this.files.length; index++) {

        attachmentArray.push({ base64: this.files[index]["base64"], file_name: this.files[index]["name"] })

      }

      var userData = {
        attachments: attachmentArray,
        text: this.textArea,
        type: this.messageType,
        copied_to_support: this.checkboxControl.value.sendCopyCheckbox ? 1 : 0
      }

      this.signUpService.sendMessage(this.token, userData, this.conversationID).subscribe(data => {
        if (data["result"]["status"] == 200) {
          this.messageData.push(data["data"])
          this.toastr.success(data["result"]["message"], 'Success');
          this.files = [];
          this.textArea = "";
          this.checkboxControl.reset();
          if (this.memberDetails?.type == 1) {
            this.messageTypeText = "Send message to Host";
            this.messageAreaTypeText = "Messages with Host";
            if (this.orderType != 'bfmOrder') {
              this.messageType = 1;
              this.textAreaPlaceholder = "Your message will be sent to Host"
              this.isShopper = true;
            } else {
              this.isShopper = false;
            }
  
          }
          else if (this.memberDetails?.type == 2) {
            this.messageTypeText = "Send message to Shopper"
            this.messageAreaTypeText = "Messages with Shopper"
            this.textAreaPlaceholder = "Your message will be sent to Shopper"
          }
          this.scrollintoview();
        }
        else {
          this.toastr.error(data["result"]["message"], 'Error');
        }
      })


    }
  }

  getCurrentGMTTime(): string {
    const currentUTCDate = new Date();
    const gmtHours = currentUTCDate.getUTCHours();
    const gmtMinutes = currentUTCDate.getUTCMinutes();
    const gmtSeconds = currentUTCDate.getUTCSeconds();
  
    return `${this.padTime(gmtHours)}:${this.padTime(gmtMinutes)}:${this.padTime(gmtSeconds)}`;
  }
  
  padTime(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }
  
  getHostTime(hostOffset: string): string {
    const currentUTCDate = new Date();
    const gmtHours = currentUTCDate.getUTCHours(); 
    const gmtMinutes = currentUTCDate.getUTCMinutes(); 
    let hostSign = 1;
    if (hostOffset.startsWith('GMT-')) {
      hostSign = -1;
    } else if (!hostOffset.startsWith('GMT+')) {
      // throw new Error('Invalid hostOffset format. Use "GMT+X" or "GMT-X".');
    }
  
    let hostOffsetHours = parseInt(hostOffset.replace('GMT+', '').replace('GMT-', ''), 10);
    if (isNaN(hostOffsetHours)) {
      hostOffsetHours = 0;
    }
    
    let hostHours = gmtHours + hostSign * hostOffsetHours;
    if (hostHours >= 24) hostHours -= 24;
    if (hostHours < 0) hostHours += 24;
    return `${this.padTime(hostHours)}:${this.padTime(gmtMinutes)}`;
  }
  
  

}
