import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'apiKeyMask'
})
export class ApiKeyMaskPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return '';
    const lastFour = value.slice(-4); 
    return '*'.repeat(value.length - 4) + lastFour;
  }
}