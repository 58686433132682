import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from "@sentry/angular";
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

let env;
if (document.location.hostname === 'shippn.com' || document.location.hostname === 'www.shippn.com') {
  env = 'prod'
} else if (document.location.hostname === 'preprodv2.shippn.com') {
  env = 'staging'
} else if (document.location.hostname === 'localhost') {
  env = 'local'
} else {
  env = 'staging'
}

Sentry.init({
  dsn: `https://3bb446c883bab5956405d0c558f56292@o4504530895765504.ingest.us.sentry.io/4507900314386432`,
  integrations: [
    Sentry.browserTracingIntegration({}),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost', /^\//],
  ignoreErrors: [
    "InvalidStateError: Failed to set the 'value' property on 'HTMLInputElement'",
    "Timeout", 
    "RangeError",
    "Cannot read properties of null (reading 'toLowerCase')",
    "NG02100", /UnknownError/i, 
    /Http failure response .*: 0 Unknown Error/,
    /Http failure response .*: 401 OK/, 
    "Uncaught (in promise): [object Undefined]",
    "AbortError: The operation was aborted"

  ],
  environment: env,
  beforeSend(event, hint) {
    const exception = hint?.originalException || "";
    const requestUrl = event?.request?.url || "";
    const responseStatusCode = event.contexts?.response?.status_code || event.extra?.status_code;
    const errorMessage = event.message || "";
    if (exception instanceof Error) {
      if (
        exception.message.includes('Timeout') ||
        exception.message.includes("InvalidStateError: Failed to set the 'value' property on 'HTMLInputElement'") ||
        exception.message.includes('401') ||
        exception.message.includes('409') ||
        exception.message.includes('404') ||
        exception.message.includes('FirebaseError') || 
        exception.message.includes('NG02100') ||
        exception.message.includes("Can't find variable: FB init") ||
        exception.message.includes('429')
      ) {
        return null;
      }
    } else if (typeof exception === 'object' && exception !== null) {
      const message = (exception as any).message;
      if (message && (
        message.includes('Timeout') ||
        message.includes("InvalidStateError: Failed to set the 'value' property on 'HTMLInputElement'")
      )) {
        return null;
      }
    }

    if (
      responseStatusCode === 401 ||
      (errorMessage.includes("Http failure response for") && requestUrl.includes("https://api.shippn.com/api/message-notifications"))
    ) {
      return null;
    }

    return event;
  },
})



if (environment.production) {
  enableProdMode();
}

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic().bootstrapModule(AppModule)
    .catch((err) => {console.log(err)});
});
