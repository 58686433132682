<div class="dialog-container">
  <div class="dialog-header">
    <h2 mat-dialog-title>Refund Shipment</h2>
    <button mat-icon-button (click)="onCancel()" class="close-button">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-dialog-content>
    <p class="dialog-message">Are you sure you want to refund the following shipment?</p>
    
    <div class="shipment-details">
      <div class="detail-row">
        <span class="label">Shipment ID:</span>
        <span class="value">{{data.shipmentId}}</span>
      </div>
      
      <div class="detail-row">
        <span class="label">Tracking code:</span>
        <span class="value">{{data.trackingCode}}</span>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button (click)="onCancel()" class="cancel-button">
      Cancel
    </button>
    <button mat-flat-button color="warn" (click)="onRefund()" class="refund-button">
      Refund
    </button>
  </mat-dialog-actions>
</div> 